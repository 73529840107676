import React from 'react';
import PropTypes from 'prop-types';
import Consult from '../../assets/icons/mdi-consulta.svg';
import Campaign from '../../assets/icons/mdi-campania.svg';
import Logout from '../../assets/icons/mdi-salir-blanco.svg';
import BitelLogo from '../../assets/bitel_logo.png';
import styles from './navbar.module.css';

export const Navbar = ({ setOpenConfirm, openExpired, setOpenExpired, setOpenLogout }) => {
  return (
    <div className={styles.navbar}>
      <div className={styles['label-container']}>
        <img src={BitelLogo} alt={'icon'} style={{ height: '40px' }} />
      </div>
      <div className={styles['buttons-container']}>
        <div
          className={styles['white-button']}
          style={openExpired ? { background: '#DDDDDD' } : {}}
          onClick={() => setOpenExpired(true)}
        >
          <img src={Campaign} className={styles.icons} alt={'icon'} />
          <p style={{ paddingTop: '2px' }}>Campañas Vencidas</p>
        </div>
        <div className={styles['blue-button']} onClick={() => setOpenConfirm(true)}>
          <img src={Consult} className={styles.icons} alt={'icon'} />
          <p style={{ paddingTop: '2px' }}>Nueva consulta</p>
        </div>
        <div className={styles['yellow-button']} onClick={() => setOpenLogout(true)}>
          <img src={Logout} className={styles.icons} alt={'icon'} />
          <p style={{ paddingTop: '2px' }}>Salir</p>
        </div>
      </div>
    </div>
  );
};

Navbar.propTypes = {
  setOpenConfirm: PropTypes.func,
  openExpired: PropTypes.bool,
  setOpenExpired: PropTypes.func,
  setOpenLogout: PropTypes.func,
};
