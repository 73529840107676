import React from 'react';
import ReactDOM from 'react-dom';
import * as serviceWorker from './serviceWorker';
import { RouterApp } from './routes';
import 'react-datepicker/dist/react-datepicker.css';
import 'normalize.css/normalize.css';
import './index.css';

ReactDOM.render(<RouterApp/>, document.getElementById('root'));

serviceWorker.unregister();
