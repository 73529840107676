import React from 'react';
import PropTypes from 'prop-types';
import { TextResponse } from './text-response';

export const RenderMessage = ({ message }) => {
  const {
    id = '',
    type = '',
    response = [],
    responseType = '',
    idSession = '',
  } = message;

  switch (type) {
    case 'watson-response':
      return (
        <TextResponse
          id={id}
          response={response}
          responseType={responseType}
          idSession={idSession}
        />
      );
    default:
      // eslint-disable-next-line no-console
      console.error('Unsupported message ↓');
      // eslint-disable-next-line no-console
      console.table(message);
      return <></>;
  }
};

RenderMessage.propTypes = {
  message: PropTypes.object,
};

