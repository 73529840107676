import React, { useState, useContext } from 'react';
import { MessagesContext } from '../../context/message-context';
import { ChatContext } from '../../context/chat-context';
import AudioRecorder from '../recorder';
import useSafariIOSDetection from '../../hooks/useIosDevice';
import styles from './input.module.css';

export const Input = () => {
  const { messageAttr } = useContext(MessagesContext);
  const { recording } = messageAttr;
  const { attr, meth } = useContext(ChatContext);
  const { message, microphonePermissions } = attr;
  const { sendMessage, dispatchChatData, setMessage, clearInput } = meth;
  const [showPlaceholder, setShowPlaceholder] = useState(true);
  const [inputStyle, setInputStyle] = useState(`${styles['w-input-container']}`);
  const { isSafari, isiOS } = useSafariIOSDetection();

  const onKeyPressed = (e) => {
    if (
      !(e.which === 13 && e.shiftKey)
      && e.key.toString().toLowerCase() === 'enter'
    ) {
      e.preventDefault();
      if (message.length > 0) {
        send();
      }
    }
  };

  const send = async () => {
    if (message.length === 0) return;
    dispatchChatData({
      type: 'new',
      payload: [
        {
          type: 'watson-response',
          response: [
            {
              message,
            },
          ],
          from: 0,
          time: new Date(),
      },
      ],
    });
    await sendMessage({
      message,
      type: 'text',
      typeUser: false,
      formatQuestion: 'text',
    });
    clearInput();
  };

  const handleInputChange = (e) => {
    const text = e.target.innerText;
    setMessage(text);
  };

  const buttonStyle = message?.length > 0
    ? `${styles['style-send-button-writting']}`
    : `${styles['style-send-button-waiting']}`;

  return (
    <div className={styles['input-container']}>
      <div className={styles['chat-input']}>
        <div className={styles['input-area']}>
          <div
            className={styles.container}
            onFocus={() => {
              setShowPlaceholder(false);
              setInputStyle(`${styles['w-input-container']} ${styles['on-input']}`);
            }}
            onBlur={() => {
              setShowPlaceholder(true);
              setInputStyle(`${styles['w-input-container']}`);
            }}
          >
            <div id={'w-input-container'} className={inputStyle}>
              <div className={styles['w-input-text-group']}>
                <div
                  id={'w-input-text'} className={styles['w-input-text']}
                  contentEditable onInput={(e) => handleInputChange(e)}
                  onKeyDown={onKeyPressed}
                ></div>
                {showPlaceholder && message.length === 0
                  ? <div className={styles['w-placeholder']}>
                    Escribe tu consulta...
                  </div>
                  : <></>
                }
              </div>
            </div>
          </div>
          {microphonePermissions && (!isSafari && !isiOS) && (
            <div className={styles['microphone-container']}>
              <AudioRecorder />
            </div>
          )}
        </div>
        <div>
          <button
            id={'button-chat'}
            className={buttonStyle}
            style={{ visibility: recording ? 'hidden' : 'visible' }}
            onClick={send}
          >
          </button>
        </div>
      </div>
    </div>
  );
};
