import React, { createContext, useMemo, useState } from 'react';
import PropTypes from 'prop-types';

export const MessagesContext = createContext('messages-context');

export const sleep = (ms = 1) => {
  return new Promise((resolve) => setTimeout(resolve, ms));
};

export const MessagesProvider = (props) => {
  const { children } = props;
  const [stream, setStream] = useState();
  const [recording, setRecording] = useState(false);
  const [loadRecord, setLoadRecord] = useState(false);
  const [hideBackArrow, setHideBackArrow] = useState(false);
  const [openMediaOptions, setOpenMediaOptions] = useState(false);

  const messageAttr = { recording, openMediaOptions, hideBackArrow, loadRecord, stream };
  let messageMeth = { setRecording, setOpenMediaOptions, setHideBackArrow, setLoadRecord };
  messageMeth = { ...messageMeth, setStream };

  const messageProvider = useMemo(() => (
    { ...props, messageAttr, messageMeth }
    // eslint-disable-next-line
  ), [messageAttr, messageMeth]);

  return (
    <MessagesContext.Provider value={messageProvider}>
      {children}
    </MessagesContext.Provider>
  );
};

MessagesProvider.propTypes = {
  children: PropTypes.node,
};
