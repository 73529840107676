import React, { useContext } from 'react';
import PropTypes from 'prop-types';
import { MessagesContext } from '../../context/message-context';
import styles from './container.module.css';

export const Container = ({ children }) => {
  const { messageAttr } = useContext(MessagesContext);
  const { recording } = messageAttr;
  return (
    <div
      id={'app-container'}
      style={recording ? { zIndex: 0 } : { zIndex: 3 }}
      className={styles.container}
    >
      {children}
    </div>
  );
};

Container.propTypes = {
  children: PropTypes.node,
};
