import { useState, useEffect } from 'react';

const useSafariIOSDetection = () => {
  const [isSafari, setIsSafari] = useState(false);
  const [isiOS, setIsiOS] = useState(false);

  useEffect(() => {
    const { userAgent } = navigator;

    setIsSafari(/^((?!chrome|android).)*safari/i.test(userAgent));
    setIsiOS(/iPad|iPhone|iPod/.test(userAgent) && !window.MSStream);
  }, []);

  return { isSafari, isiOS };
};

export default useSafariIOSDetection;
