import { apiLoad, escalateError, getResponseData } from './index';

export default class CampaignApi {
  static getExpiredCampaigns(data) {
    const { text, startDate = '', endDate = '', page, search = '' } = data;
    return apiLoad.get(
      `campaign/search?text=${text}&startDate=${startDate}
      &endDate=${endDate}&page=${page}&search=${search}`
    )
      .then(getResponseData)
      .catch(escalateError);
  };
}
