import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { Margin } from '../margin';
import { Divider } from '../divider';
import { Pagination } from '../pagination';
import ChatApi from '../../api/chatApi';
import formatText from '../../utils/textFormater';
import NoResultsIcon from '../../assets/icons/mdi-sin-resultados.svg';
import styles from './list.module.css';

export const List = ({ list, search, clearResults }) => {
  const [selectedItem, setSelectedItem] = useState(null);

  const { results, pagination, interactionId } = list;

  const handleItem = async (item) => {
    if (!item) return;
    setSelectedItem(item);
    await ChatApi.sendEvents({
      id: interactionId,
      eventType: item?.title,
      description: item?.title,
      option: 'message_events',
    });
  };

  const { title, content = '' }
    = selectedItem || {};

  const handleBack = () => {
    setSelectedItem(null);
  };

  const campaignContent = content?.replace(/\r/g, '')
    ?.replace(/\t/g, '')
    ?.replace(/\n{3,}/g, '\n');

  const extractCampaignDates = (text) => {
    const regex = /Campaña válida desde el (\d{2}\/\d{2}\/\d{4}) hasta el (\d{2}\/\d{2}\/\d{4})/;
    const match = text.match(regex);
    if (match) {
      const [, startDate, endDate] = match;
      return { startDate, endDate };
    }
    const today = new Date();
    const day = String(today.getDate()).padStart(2, '0');
    const month = String(today.getMonth() + 1).padStart(2, '0');
    const year = String(today.getFullYear());
    const actualDates = {
      startDate: `${day}/${month}/${year}`, endDate: `${day}/${month}/${year}`,
    };
    return actualDates;
  };

  useEffect(() => {
    if (clearResults) {
      setSelectedItem(null);
    }
  }, [clearResults]);

  useEffect(() => {
    setSelectedItem(null);
  }, [results]);

  if (results?.length <= 0) {
    return (
      <div className={styles['empty-container']}>
        <img src={NoResultsIcon} alt={'no result'} className={styles['no-result-icon']} />
        <Margin bottom={1.6} />
        <p className={styles['light-text']} style={{ textAlign: 'center' }}>
          <p className={styles['bold-text']}>
            No se encontraron resultados.
          </p>
          <br />
          <span className={styles['bold-text']}>
            Por favor busque tanto por Título o por Descripción.{' '}
          </span>
          Además revise los términos utilizados, intente con diferentes palabras o
          verifique el rango de fechas seleccionado.
        </p>
      </div>
    );
  }

  return (
    <>
      {pagination?.totalPages > 1 && !selectedItem && (
        <>
          <Margin bottom={1.9} />
          <div className={styles['pagination-container']}>
            <Pagination pagination={pagination} search={search} />
          </div>
        </>
      )}
      <div
        className={styles.container}
        style={selectedItem
          ? { maxHeight: '425px', marginTop: '24px' }
          : pagination?.totalPages > 1
            ? { maxHeight: '440px', marginTop: '10px' }
            : { maxHeight: '485px', marginTop: '10px' }
        }
      >
        {!selectedItem && (results || []).map((it, index) => {
          const { title, description, content } = it;
          const campaignDescription = description?.replace(/-url:|url:|dinámica:|-dinámica:/g, '')
            ?.replace(/\r/g, '')
            ?.replace(/\n{2,}/g, ' ');
          const { startDate, endDate } = extractCampaignDates(content);
          return (
            <div key={title + index} className={styles.item} onClick={() => handleItem(it)}>
              <Margin bottom={1.6}/>
              <p className={styles['bold-text']}>{title}</p>
              {description && (
                <>
                  <Margin bottom={0.2} />
                  <div className={styles['description-container']}>
                    <span
                      className={styles['light-text']}
                      dangerouslySetInnerHTML={formatText(campaignDescription?.trim())}
                    />
                  </div>
                  <Margin bottom={0.4} />
                </>
              )}
              {startDate && endDate && (
                <div className={styles['dates-container']}>
                  <span className={styles.active}>●&nbsp;</span>Vigencia&nbsp;
                  <p className={styles['light-text']}>
                    {startDate} - {endDate}
                  </p>
                </div>
              )}
              {index < results.length - 1 && (
                <>
                  <Margin bottom={1.6} />
                  <Divider />
                </>
              )}
            </div>
          );
        })}
        {!clearResults && (selectedItem && (
          <>
            <div className={styles.item}>
              <Margin bottom={1.6} />
              <p className={styles['bold-text']}>{title}</p>
              <Margin bottom={0.2} />
              <div className={styles['content-container']}>
                <span
                  className={styles['light-text']}
                  dangerouslySetInnerHTML={formatText(campaignContent)}
                />
              </div>
            </div>
          </>
        ))}
      </div>
      {selectedItem && (
        <>
          <Margin bottom={2} />
          <button className={styles['back-button']} onClick={handleBack}>
            Volver
          </button>
        </>
      )}
    </>
  );
};

List.propTypes = {
  list: PropTypes.array,
  search: PropTypes.func,
  clearResults: PropTypes.bool,
};
