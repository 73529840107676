/* eslint-disable max-lines */
import {
  MessageClassifier, secondClassifier, thirdClassifier
} from './message-classifier';

export default class ChatUtils {
  static detectBrowser = () => {
    try {
      return (function (agent) {
        switch (true) {
          case agent.indexOf('edge') > -1:
            return 'edge';
          case agent.indexOf('edg') > -1:
            return 'chromium-based-edge';
          case agent.indexOf('opr') > -1 && !!window.opr:
            return 'opera';
          case agent.indexOf('chrome') > -1 && !!window.chrome:
            return 'chrome';
          case agent.indexOf('trident') > -1:
            return 'ie';
          case agent.indexOf('firefox') > -1:
            return 'firefox';
          case agent.indexOf('safari') > -1:
            return 'safari';
          default:
            return 'other';
        }
      })(window.navigator.userAgent.toLowerCase());
    } catch (e) {
      return 'unknown';
    }
  };

  static chatDataReducer = (data, action) => {
    switch (action.type) {
      case 'convo':
        return {
          ...data,
          conversationId: action.conversationId,
          conversationStart: action.conversationStart,
        };
      case 'new':
        return { ...data, new: [action.payload] };
      case 'clear-new':
        return { ...data, new: [] };
      case 'add':
        return { ...data, messages: [...data.messages, action.payload] };
      case 'clear-messages':
        return { ...data, messages: [] };
      default:
        return { ...data };
    }
  };

  static typeMessages = ({
    id,
    Nodo = '',
    ally = '',
    fromUser,
    dateTime,
    responseText,
    responseType,
    typeQuestion,
    idSession = '',
    type = '',
    contextResponseType = '',
  }) => {
    const responses = [];
    if (typeof (responseText) === 'string') {
      responses.push({
        id,
        ally,
        type: 'watson-response',
        response: [{
          message: responseText,
        }],
        typeQuestion,
        from: fromUser === 'system' ? 1 : 0,
        time: dateTime,
        responseType,
        idSession,
      });
    } else {
      if (type === 'watson' || type === 'text') {
        const response = MessageClassifier({ responseText, Nodo });
        if (response.length === 0) return responses;
        if (
          response?.[0]?.text !== 'Sin resultado'
          || response?.[0]?.text !== 'interface-home'
          || response?.[0]?.text !== 'interface-home '
        ) {
          responses.push({
            id,
            ally,
            type: 'watson-response',
            response,
            typeQuestion,
            from: fromUser === 'system' ? 1 : 0,
            time: dateTime,
            responseType,
            idSession,
          });
        }
      }
      if (type === 'options' || contextResponseType === 'menu') {
        if (
          responseText.filter((resp) => resp.response_type === 'image').length > 0
        ) {
          const response = secondClassifier({
            id,
            responseText,
            from: fromUser === 'system' ? 1 : 0,
            time: dateTime,
            responseType,
            idSession,
          });
          responses.push(...response);
        } else {
          const response = thirdClassifier({ responseText });
          responses.push({
            id,
            ally,
            type: 'watson-response',
            response,
            typeQuestion,
            from: fromUser === 'system' ? 1 : 0,
            time: dateTime,
            responseType,
            idSession,
          });
        }
      } else {
        if (
          responseText !== 'Sin resultado'
        ) {
          return responses;
        }
        responses.push({
          id,
          type: 'text',
          text: responseText,
          from: fromUser === 'system' ? 1 : 0,
          time: dateTime,
          responseType,
          idSession,
        });
      }
    }
    return responses;
  };

  static extensionFile = (typeFile) => {
    const extensions = {
      audio: 'audio',
      jpg: 'image',
      png: 'image',
      svg: 'image',
      jpeg: 'image',
      doc: 'file',
      docx: 'file',
      pdf: 'file',
      csv: 'file',
      txt: 'file',
      xlsx: 'file',
      pptx: 'file',
      mp3: 'audio',
      wav: 'audio',
      flac: 'audio',
      wma: 'audio',
      aac: 'audio',
      ogg: 'audio',
    };
    return extensions[typeFile] || undefined;
  };

  static formatAMPM = (date) => {
    const year = date.getFullYear();
    let day = date.getDate();
    day = day < 10 ? `0${day}` : day;
    let month = parseInt(date.getMonth()) + 1;
    month = month < 10 ? `0${month}` : month;
    let hours = date.getHours();
    let minutes = date.getMinutes();
    const ampm = hours >= 12 ? 'PM' : 'AM';
    hours = hours % 12;
    hours = hours || 12;
    hours = hours < 10 ? `0${hours}` : hours;
    minutes = minutes < 10 ? `0${minutes}` : minutes;
    return `${year}-${month}-${day} ${hours}:${minutes} ${ampm}`;
  };
}
