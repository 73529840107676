import React from 'react';
import PropTypes from 'prop-types';
import { Margin } from '../margin';
import styles from './pagination.module.css';

export const Pagination = ({ search, pagination }) => {
  const { currentPage, totalPages, totalResults } = pagination;

  const disableBack = currentPage === 1;
  const disableNext = currentPage === totalPages;

  const handleBack = () => {
    if (disableBack) return;
    search(currentPage - 1);
  };

  const handleNext = () => {
    if (disableNext) return;
    search(currentPage + 1);
  };

  return (
    <div className={styles.container}>
      <div className={styles.label}>
        {currentPage || 0}-{totalResults || 0} de {totalPages || 0}
      </div>
      <Margin left={1.6}/>
      <div
        className={`${styles.arrow} ${styles.back} ${disableBack ? styles['disabled-back'] : ''}`}
        onClick={() => handleBack()}
      ></div>
      <Margin left={1.6}/>
      <div
        className={`${styles.arrow} ${styles.next} ${disableNext ? styles['disabled-next'] : ''}`}
        onClick={() => handleNext()}
      ></div>
    </div>
  );
};

Pagination.propTypes = {
  search: PropTypes.func,
  pagination: PropTypes.object,
};
