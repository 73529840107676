import React from 'react';
import PropTypes from 'prop-types';

export const Margin = ({ bottom, top, left, right, ...props }) => {
  return (
    <div
      {...props}
      style={{
        marginBottom: `${bottom * 10}px`,
        marginTop: `${top * 10}px`,
        marginLeft: `${left * 10}px`,
        marginRight: `${right * 10}px`,
      }}
    />
  );
};

Margin.propTypes = {
  bottom: PropTypes.number,
  top: PropTypes.number,
  left: PropTypes.number,
  right: PropTypes.number,
};
