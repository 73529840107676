/* eslint-disable max-lines */
export const returnCase = ({
  textAndIncludes,
  responseText,
  index,
  imageAndIncludes,
}) => {
  if (
    textAndIncludes({
      response: responseText?.[index],
      word: 'Sin resultado',
    })
  ) {
    return true;
  } else if (
    textAndIncludes({ response: responseText?.[index], word: 'mdi' })
    && textAndIncludes({
      response: responseText?.[index - 1],
      word: 'box-drop-down',
    })
  ) {
    return true;
  } else if (
    imageAndIncludes({ words: ['box-info', 'box-commerce', 'step'] })
  ) {
    return true;
  } else return false;
};

const flags = ({ flag1, flag2 }) => flag1 && flag2;
const flags3 = ({ flag1, flag2, flag3 }) => flag1 && flag2 && flag3;

const includesText = ({ textAndIncludes, item }) => {
  const { response_type: responseType = '', text = '', source } = item;
  const response = [];
  if (
    textAndIncludes({
      response: item,
      word: 'warning-box-field',
    })
  ) {
    response.push({ alert: text });
    return response;
  }
  if (textAndIncludes({ response: item, word: 'mdi-box-icon' })) {
    response.push({ boxDotIcons: text });
    return response;
  }
  if (textAndIncludes({ response: item, word: 'mdi' })) {
    if (text?.includes('\n\n')) {
      response.push({ icons: text.split('\n\n') });
      return response;
    }
    response.push({ icon: text });
    return response;
  }
  if (textAndIncludes({ response: item, word: 'btn-link' })) {
    response.push({ btnlink: text });
    return response;
  }
  if (responseType === 'image') {
    response.push({ image: source });
    return response;
  }
  if (responseType === 'video') {
    response.push({ video: source });
    return response;
  }
  return response;
};

export const MessageClassifier = ({ responseText }) => {
  let bonus = [];
  let boxInfo = [];
  let btnder = [];
  let dropdown = [];
  let stepper = [];
  let stepperDropdown = [];
  let bonusIndex = null;
  let boxInfoIndex = null;
  let btnderIndex = null;
  let dropdownIndex = null;
  let stepperIndex = null;

  const resetComponents = () => {
    bonus = [];
    boxInfo = [];
    btnder = [];
    dropdown = [];
    stepper = [];
    stepperDropdown = [];
    bonusIndex = null;
    boxInfoIndex = null;
    btnderIndex = null;
    dropdownIndex = null;
    stepperIndex = null;
  };

  const response = [];
  responseText.forEach((item, index) => {
    const { response_type: responseType = '', text = '' } = item;

    const textAndIncludes = ({ response, word }) =>
      flags({
        flag1: response?.response_type === 'text',
        flag2: response?.text?.toLowerCase()?.includes(word),
      });

    const imageAndIncludes = ({ words = [] }) =>
      flags({
        flag1: responseType === 'image',
        flag2: words.some((word) =>
          textAndIncludes({ response: responseText?.[index - 1], word })
        ),
      });

    const nextImageAndIncludes = (word) =>
      flags3({
        flag1: responseType === 'text',
        flag2: textAndIncludes({ response: item, word }),
        flag3: responseText?.[index + 1]?.response_type === 'image',
      });

    const textRender = includesText({ textAndIncludes, item });

    const returnFlag = returnCase({
      textAndIncludes,
      responseText,
      index,
      imageAndIncludes,
    });

    if (returnFlag) {
      return;
    }

    if (responseType === 'gpt') {
      const { text } = item;
      resetComponents();
      response.unshift({ gpt: text });
      return;
    }

    if (responseType === 'message') {
      const { text } = item;
      resetComponents();
      response.push({ message: text });
      return;
    }

    if (responseType === 'button') {
      resetComponents();
      if (btnderIndex === null) {
        btnderIndex = index;
      }
      btnder.push({ btnName: item.text, btnUrl: item.derUrl });
      response[btnderIndex] = { btnder };
      return;
    }

    if (
      flags({
        flag1: textAndIncludes({
          response: item,
          word: 'box-drop-down',
        }),
        flag2: textAndIncludes({
          response: responseText?.[index + 1],
          word: 'mdi',
        }),
      })
      || textAndIncludes({
        response: item,
        word: 'box-drop-down',
      })
    ) {
      resetComponents();
      if (dropdownIndex === null) {
        dropdownIndex = index;
      }
      dropdown.push({
        dropdown: {
          text,
          icons: responseText?.[index + 1]?.text.split('\n\n'),
        },
      });
      response[dropdownIndex] = { dropdown };
      return;
    }
    if (
      textAndIncludes({
        response: responseText?.[index],
        word: 'box-select',
      })
    ) {
      resetComponents();
      response.push({ boxSelect: text });
      return;
    }
    if (
      textAndIncludes({
        response: responseText?.[index],
        word: 'option-box',
      })
    ) {
      resetComponents();
      response.push({ optionBox: text });
      return;
    }
    if (nextImageAndIncludes('box-commerce')) {
      if (bonusIndex === null) {
        bonusIndex = index;
      }
      bonus.push({ text, img: responseText?.[index + 1]?.source });
      response[bonusIndex] = { bonus };
      return;
    }
    if (
      nextImageAndIncludes('step')
      && !nextImageAndIncludes('step_dropdown')
    ) {
      if (stepperIndex === null) {
        stepperIndex = index;
      }
      stepper.push({ text, img: responseText?.[index + 1]?.source });
      response[stepperIndex] = { stepper };
      return;
    }
    if (nextImageAndIncludes('step') && nextImageAndIncludes('step_dropdown')) {
      if (stepperIndex === null) {
        stepperIndex = index;
      }
      stepperDropdown.push({ text, img: responseText?.[index + 1]?.source });
      response[stepperIndex] = { stepperDropdown };
      return;
    }
    if (
      nextImageAndIncludes('box-info')
      || textAndIncludes({
        response: responseText?.[index],
        word: 'box-info-text',
      })
      || textAndIncludes({
        response: responseText?.[index],
        word: 'box-info btn-link',
      })
    ) {
      if (boxInfoIndex === null) {
        boxInfoIndex = index;
      }
      if (text.includes('box-info btn-link')) {
        const btns = JSON.parse(text);
        const subBtnlink = btns[btns.length - 1];
        if (subBtnlink?.optionList.length > 0) {
          boxInfo.push({
            text: '', image: '', btnlink: btns.slice(1, 4), subBtnlink: subBtnlink?.optionList,
          });
        } else {
          boxInfo.push({
            text: '', image: '', btnlink: btns.slice(1),
          });
        }
      } else {
        boxInfo.push({ text, image: responseText?.[index + 1]?.source });
      }
      response[boxInfoIndex] = { boxInfo };
      return;
    }

    if (textRender.length > 0) {
      resetComponents();
      response.push(...textRender);
      return;
    }

    resetComponents();
    response.push({ text });
  });
  return response;
};

export const secondClassifier = ({ responseText }) => {
  const responses = [];
  responseText.forEach((it, index) => {
    if (
      it?.response_type === 'option'
      && responseText?.[index - 1]?.response_type === 'image'
    ) {
      responses.push({
        image: responseText?.[index - 1],
        options: it,
        type: 'card',
        time: it.time || new Date(),
      });
    }
    if (
      it?.response_type === 'text'
      && responseText?.[index + 1]?.response_type === 'image'
      && responseText?.[index + 2]?.response_type === 'video'
    ) {
      responses.push({
        type: responseText?.[index + 1].response_type,
        imgURL: responseText?.[index + 1].source,
        videoURL: responseText?.[index + 2].source,
        title: responseText?.[index + 1].title,
      });
    }
    if (
      it?.response_type === 'text'
      && responseText?.[index + 1]?.response_type === 'image'
    ) {
      responses.push({
        type: responseText?.[index + 1].response_type,
        imgURL: responseText?.[index + 1].source,
        title: responseText?.[index + 1].title,
      });
    }
  });
  return responses;
};

export const thirdClassifier = ({ responseText }) => {
  const responses = [];
  responseText.forEach((textMessages) => {
    if (
      textMessages.response_type === 'option'
    ) {
      responses.push({
        ...textMessages,
        type: textMessages.response_type || textMessages.message,
        from: 1,
        time: textMessages.time || new Date(),
      });
    }
  });
  return responses;
};
