
import React from 'react';
import PropTypes from 'prop-types';
import styles from './buttons.module.css';

export const Button = ({ children, ...props }) => {
  const { color = 'red', disabled = false, outlined, icon } = props;

  const disableStyle = disabled ? `${styles.disabled}` : '';

  if (outlined) {
    return (
      <button
        {...props}
        className={`${styles.button} ${styles[`${color}-outlined`]} ${disableStyle}`}
      >
        {icon ? <img src={icon} className={styles.icon} alt={'icon button'} /> : <></>} {children}
      </button>
      );
  }

  return (
    <button
      {...props}
      className={`${styles.button} ${styles[`${color}-filled`]} ${disableStyle}`}
    >
      {icon ? <img src={icon} className={styles.icon} alt={'icon button'} /> : <></>} {children}
    </button>
  );
};

Button.propTypes = {
  color: PropTypes.string,
  icon: PropTypes.string,
  disabled: PropTypes.bool,
  outlined: PropTypes.bool,
  children: PropTypes.node,
};
