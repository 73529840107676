import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { sleep } from '../../context/message-context';
import { List } from '../list';
import { Margin } from '../margin';
import { DateRange } from '../date-range';
import { Tooltip } from '../tooltip';
import { Button } from '../buttons';
import { Select } from '../select';
import CampaignApi from '../../api/campaignsApi';
import SearchBlanco from '../../assets/icons/mdi-buscar-blanco.svg';
import Reset from '../../assets/icons/mdi-restablecer.svg';
import Information from '../../assets/icons/mdi-informacion.svg';
import styles from './expired.module.css';

export const ExpiredCampaigns = ({ open, setOpen }) => {
  const [inputMessage, setInputMessage] = useState('');
  const [cardStyle, setCardStyle] = useState(`${styles.in}`);
  const [inputStyle, setInputStyle] = useState(
    `${styles['w-input-container']}`
  );
  const [showPlaceholder, setShowPlaceholder] = useState(true);
  const [endDate, setEndDate] = useState();
  const [startDate, setStartDate] = useState();
  const [campaignList, setCampaignList] = useState(null);
  const [searching, setSearching] = useState(false);
  const [optionFilter, setOptionFilter] = useState('title');
  const [clearResults, setClearResults] = useState(false);

  const options = [
    { label: 'Título', value: 'title' },
    { label: 'Descripción', value: 'description' },
  ];

  useEffect(() => {
    if (!open) clearInput();
  }, [open]);

  const onKeyPressed = (e) => {
    if (
      !(e.which === 13 && e.shiftKey)
      && e.key.toString().toLowerCase() === 'enter'
    ) {
      e.preventDefault();
      if (inputMessage?.length > 0) {
        search(1);
      }
    }
  };

  const handleInputChange = (e) => {
    const text = e.target.innerText;
    setInputMessage(text);
    setSearching(false);
  };

  const clearInput = () => {
    const divElement = document.getElementById('w-input-text');
    if (divElement) {
      divElement.innerText = '';
      setInputMessage('');
    }
    setCampaignList([]);
    setStartDate();
    setEndDate();
  };

  const handleClose = async () => {
    setCardStyle(`${styles.out}`);
    clearInput();
    await sleep(500);
    setOpen(false);
    setCardStyle(`${styles.in}`);
  };

  const search = (page) => {
    setSearching(true);
    setClearResults(false);
    const data = {
      text: inputMessage ? inputMessage?.toLocaleLowerCase() : '',
      startDate,
      endDate,
      page,
      search: optionFilter,
    };
    CampaignApi.getExpiredCampaigns(data)
      .then((res) => {
        setCampaignList(res);
      })
      .catch((err) => {
        // eslint-disable-next-line no-console
        console.error(err);
      });
  };

  const reset = () => {
    setCampaignList([]);
    setStartDate(null);
    setEndDate(null);
    clearInput();
    setSearching(false);
    setClearResults(true);
  };

  if (!open) return <></>;

  return (
    <div className={`${styles.container} ${cardStyle}`}>
      <div className={styles.content}>
        <div className={styles['card-top']}>
          <strong style={{ fontSize: '18px', lineHeight: '20px' }}>
            Campañas Vencidas
          </strong>
          <Tooltip
            icon={Information}
            text={
              'Puede buscar solo por título o descripción, solo por rango de fechas'
              + ' o combinar ambos criterios en su búsqueda.'
            }
            position={'right'}
          />
          <p className={styles.close} onClick={handleClose}>
            Cerrar
          </p>
        </div>
        <Margin bottom={2.78} />
        <div className={styles['input-container']}>
          <div className={styles.select}>
            <Select
              options={options}
              onChange={(e) => {
                setOptionFilter(e.target.value);
                setSearching(false);
              }}
            />
          </div>
          <div
            className={styles['container-input']}
            onFocus={() => {
              setShowPlaceholder(false);
              setInputStyle(
                `${styles['w-input-container']} ${styles['on-input']}`
              );
            }}
            onBlur={() => {
              setShowPlaceholder(true);
              setInputStyle(`${styles['w-input-container']}`);
            }}
          >
            <div id={'w-input-container'} className={inputStyle}>
              <div className={styles['w-input-text-group']}>
                <div
                  id={'w-input-text'}
                  className={styles['w-input-text']}
                  contentEditable
                  onInput={(e) => handleInputChange(e)}
                  onKeyDown={onKeyPressed}
                ></div>
                {showPlaceholder && inputMessage?.length === 0 && (
                  <div className={styles['w-placeholder']}>
                    Ingrese la campaña a buscar
                  </div>
                )}
              </div>
            </div>
          </div>
        </div>
        <Margin bottom={1.2} />
        <div className={styles['dates-container']}>
          <DateRange
            startDate={startDate}
            setStartDate={setStartDate}
            endDate={endDate}
            setEndDate={setEndDate}
          />
        </div>
        <Margin bottom={1.1} />
        <Margin bottom={1.1} />
        <div className={styles['buttons-container']}>
          <Button
            color={'white'}
            icon={Reset}
            onClick={reset}
            style={{ borderRadius: '8px', maxWidth: '40px' }}
          />
          <Button
            color={!searching ? 'green' : 'disabled'}
            icon={SearchBlanco}
            onClick={() => search(1)}
            style={{ borderRadius: '8px' }}
            disabled={!!searching}
          >
            Buscar
          </Button>
        </div>
        {campaignList && <List list={campaignList} search={search} clearResults={clearResults} />}
      </div>
    </div>
  );
};

ExpiredCampaigns.propTypes = {
  open: PropTypes.bool,
  setOpen: PropTypes.func,
};
