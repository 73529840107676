import React, { useRef, useState } from 'react';
import PropTypes from 'prop-types';
import { Margin } from '../margin';
import DatePicker from 'react-datepicker';
import es from 'date-fns/locale/es';
import './dateRange.css';

export const DateRange = ({ startDate, endDate, setStartDate, setEndDate }) => {
  const datePickerStartRef = useRef();
  const datePickerEndRef = useRef();
  const [openEndDate, setOpenEndDate] = useState(false);
  const [openStartDate, setOpenStartDate] = useState(false);

  const customLocale = {
    ...es,
    localize: {
      ...es.localize,
      month: (n, { width, context }) => {
        const month = es.localize.month(n, { width, context });
        return month.charAt(0).toUpperCase() + month.slice(1);
      },
      day: (n, { width, context }) => {
        const day = es.localize.day(n, { width, context });
        return day.charAt(0).toUpperCase() + day.slice(1);
      },
    },
  };

  return (
    <>
      <div className={'picker-container'}>
        <p className={'date-label'}>Desde</p>
        <Margin bottom={0.4} />
        <div className={'date-picker-container-left'}>
          <DatePicker
            selectsStartstartDate={startDate}
            open={openStartDate}
            endDate={endDate}
            selected={startDate}
            locale={customLocale}
            ref={datePickerStartRef}
            placeholderText={'DD/MM/AAAA'}
            className={'date-picker-input left'}
            dateFormat={'dd/MM/yyyy'}
            onChange={(date) => {
              setStartDate(date);
              setOpenStartDate(false);
              setOpenEndDate(true);
            }}
            calendarClassName={'calendar'}
            onInputClick={() => setOpenStartDate(true)}
          />
        </div>
      </div>
      <div className={'picker-container'}>
        <p className={'date-label'}>Hasta</p>
        <Margin bottom={0.4} />
        <div className={'date-picker-container-right'}>
          <DatePicker
            selectsEnd
            open={openEndDate}
            startDate={startDate}
            endDate={endDate}
            minDate={startDate}
            selected={endDate}
            locale={customLocale}
            ref={datePickerEndRef}
            placeholderText={'DD/MM/AAAA'}
            className={'date-picker-input right'}
            dateFormat={'dd/MM/yyyy'}
            onChange={(date) => {
              setEndDate(date);
              setOpenEndDate(false);
            }}
            calendarClassName={'calendar right-picker'}
          />
        </div>
      </div>
    </>
  );
};

DateRange.propTypes = {
  startDate: PropTypes.any,
  endDate: PropTypes.any,
  setStartDate: PropTypes.func,
  setEndDate: PropTypes.func,
};
