import React, { createContext, useMemo, useState } from 'react';
import PropTypes from 'prop-types';

export const PopupContext = createContext('popup-context');

export const PopupProvider = (props) => {
  const { children } = props;
  const [openNps, setOpenNps] = useState(false);
  const [openLogout, setOpenLogout] = useState(false);
  const [openConfirm, setOpenConfirm] = useState(false);
  const [openExpired, setOpenExpired] = useState(false);
  const [openInactive, setOpenInactive] = useState(false);

  const popupAttr = { openNps, openLogout, openConfirm, openExpired, openInactive };
  const popupMeth = { setOpenNps, setOpenLogout, setOpenConfirm, setOpenExpired, setOpenInactive };

  const popupProvider = useMemo(() => (
    { ...props, popupAttr, popupMeth }
    // eslint-disable-next-line
  ), [popupAttr, popupMeth]);

  return (
    <PopupContext.Provider value={popupProvider}>
      {children}
    </PopupContext.Provider>
  );
};

PopupProvider.propTypes = {
  children: PropTypes.node,
};

