import React, { useState } from 'react';
import PropTypes from 'prop-types';
import styles from './select.module.css';

export const Select = ({ options, value, onChange }) => {
  const [isOpen, setIsOpen] = useState(false);

  return (
    <div className={styles.selectWrapper}>
      <select
        className={`${styles.select} ${isOpen ? styles.open : ''}`}
        value={value}
        onChange={onChange}
        onBlur={() => setIsOpen(false)}
        onClick={() => setIsOpen(!isOpen)}
      >
        {options && options.map((option) => (
          <option key={option.value} value={option.value}>
            {option.label}
          </option>
        ))}
      </select>
    </div>
  );
};

Select.propTypes = {
  options: PropTypes.arrayOf(
    PropTypes.shape({
      value: PropTypes.string,
      label: PropTypes.string,
    })
  ).isRequired,
  value: PropTypes.string,
  onChange: PropTypes.func,
};
