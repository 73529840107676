import React from 'react';
import PropTypes from 'prop-types';
import styles from './tooltip.module.css';

export const Tooltip = ({ icon, text, position }) => {
  return (
    <div className={`${styles.tooltip} ${styles[position]}`}>
      <img src={icon} alt={'search'} />
      <span className={`${styles.tooltiptext} ${styles[position]}`}>{text}</span>
    </div>
  );
};

Tooltip.propTypes = {
  icon: PropTypes.string,
  text: PropTypes.string,
  position: PropTypes.oneOf(['top', 'right', 'bottom', 'left']),
};
