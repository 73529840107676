import { apiHandshake, escalateError, getResponseData } from './index';

export default class HandshakeApi {
  static getCookie() {
    return apiHandshake.get('/redirect/user/userId')
      .then(getResponseData)
      .catch(escalateError);
  };

  static validateDNI(data) {
    return apiHandshake.post('api/user/search', data)
      .then(getResponseData)
      .catch(escalateError);
  }
}
