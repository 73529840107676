import axios from 'axios';

const apiURL = process.env.REACT_APP_VISOR;
const apiLOAD = process.env.REACT_APP_LOAD;
const apiHANDSHAKE = process.env.REACT_APP_HANDSHAKE;
const apiINTERBANKURL = process.env.REACT_APP_DAVIPLATA;
const apiWEBSITE = process.env.REACT_APP_WEBSITE_BACKEND_SERVICE;

let header = {
  'Content-Type': 'application/json',
  Authorization: localStorage.getItem('token'),
};

export let api = axios.create({
  baseURL: `${apiURL}/adminui-backend/api`,
  headers: header,
});

export let apiLoad = axios.create({
  baseURL: apiLOAD,
  headers: header,
});

export let apiWebsite = axios.create({
  baseURL: apiWEBSITE,
  headers: header,
});

export let apiHandshake = axios.create({
  baseURL: apiHANDSHAKE,
  headers: header,
});

export let apiInterbank = axios.create({
  baseURL: apiINTERBANKURL,
  headers: header,
});

export const refreshToken = (token) => {
  if (token) {
    header = {
      'Content-Type': 'application/json',
      Authorization: token,
    };

    api = axios.create({
      baseURL: apiURL,
      headers: header,
    });

    apiLoad = axios.create({
      baseURL: apiHANDSHAKE,
      headers: header,
    });

    apiHandshake = axios.create({
      baseURL: apiHANDSHAKE,
      headers: header,
    });

    apiInterbank = axios.create({
      baseURL: apiINTERBANKURL,
      headers: header,
    });

    apiWebsite = axios.create({
      baseURL: apiWEBSITE,
      headers: header,
    });
  }
};

export const getApiURL = () => apiURL;
export const getResponseData = (resp) => resp.data;

export const escalateError = (err) => {
  let errorFromResponse;
  try {
    errorFromResponse = err.response.data.error.toString();
  } catch (e) {
    errorFromResponse = undefined;
  }
  const firstValidation = typeof err === 'string' ? err : err.toString();
  const secondValidation
    = err instanceof Error ? err.message || err.toString() : firstValidation;
  const newErr = new Error(
    errorFromResponse || secondValidation || 'Error Inesperado'
  );
  try {
    newErr.data = err.response.data;
  } catch (e) {}
  throw newErr;
};

export default api;
