import {
  apiInterbank, apiHandshake, getResponseData, escalateError
} from './index';

export default class ChatApi {
  static convertToText(bufferAudio, token) {
    return apiInterbank.post('chats/recognize', { buffer: bufferAudio, token })
      .then((resp) => getResponseData(resp.data))
      .catch(escalateError);
  }

  static getIdentificator() {
    return apiInterbank.get('user/session')
      .then((res) => {
        const { idSession } = res.data.data;
        return idSession;
      })
      .catch(escalateError);
  }

  static redirect({ ally, url, path }) {
    return apiHandshake.get(`redirect/provider/derived?ally=${ally}&path=${path}&url=${url}`)
      .then(getResponseData)
      .catch(escalateError);
  }

  static sendEvents({ id, eventType, description, device, option }) {
    return apiInterbank.post('insertChatEvent', {
      id_message: id,
      event_type: eventType,
      description,
      device,
      option,
    })
      .then(getResponseData);
  }

  static sendNPS(data) {
    return apiInterbank.post('user/session/form', data)
      .then(getResponseData)
      .catch(escalateError);
  }
}
